<template>
  <div class="main-content">
    <div class="header">
      <div class="container-fluid">
        <!-- Body -->
        <div class="header-body">
          <div class="row align-items-center">
            <div class="col">
              <h6 class="header-pretitle">
                {{ $t("menu.dashboard") }}
              </h6>
              <h1 class="header-title">
                {{ $t("keys.title_all") }}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-container fluid>
      <b-row>
        <b-col cols="12">
          <div class="card">
            <div class="card-header">
              <!-- Title -->
              <h4 class="card-header-title"></h4>
              <form class="mr-3">
                <Select2
                  v-model="perPage"
                  :options="perPageOptions"
                  :settings="{
                    minimumResultsForSearch: -1,
                    containerCssClass:
                    'custom-select custom-select-sm form-control-flush',
                    dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                  }"
                  @change="paginatedList"                         
                />
              </form> 
              <!-- Select -->
              <form class="mr-3">
                <Select2
                  v-model="sortOrder"
                  :options="sortOrderOptions"
                  :settings="{
                    minimumResultsForSearch: -1,
                    containerCssClass:
                      'custom-select custom-select-sm form-control-flush',
                    dropdownCssClass: 'dropdown-menu dropdown-menu-sm dropdown-menu-addition',
                  }"                         
                />               
              </form>
            </div>
            <div class="card-header">
              <!-- Form -->
              <form>
                <div class="input-group input-group-flush input-group-merge">
                  <!-- Input -->

                  <b-form-input
                    :placeholder="$t('common.search')"
                    v-model="filter"
                    class="form-control form-control-prepended search"
                    type="search"
                  ></b-form-input>

                  <!-- Prepend -->
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <span class="fe fe-search"></span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="card-body">
              <b-pagination
                v-model="currentPage"                                
                :total-rows="keysList.length"
                :per-page="perPage"
                aria-controls="my-table"
                class="pb-2"
              ></b-pagination>
              <zs-list-transition :showLoader="showLoader">
                <template v-slot:content>
                  <!-- v-for="(doorKey, index) in keysList" -->
                  <key-list-item
                    v-for="(doorKey, index) in paginatedList"
                    v-bind:key="doorKey.Key.Id + '-' + index"
                    :doorKey="doorKey"
                    :showDeleting="false"
                    class="flex-column align-items-start"
                  />
                </template>
                <template v-slot:empty>
                  <empty-list :title="$t('keys.no_keys')" />
                </template>
              </zs-list-transition>
              <b-pagination
                v-model="currentPage"                                
                :total-rows="keysList.length"
                :per-page="perPage"
                aria-controls="my-table"
                class="pb-2"
              ></b-pagination>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import KeyListItem from "@/components/key/KeyListItem";
import ListTransition from "@/components/common/ListTransition";
import EmptyList from "@/components/common/EmptyList";
import Select2 from "@/components/common/Select2";

import { mapGetters } from "vuex";

import { USER_GET_KEYS } from "@/store/types/user";
import { LOCATIONS_GET_ALL_LOCATIONS } from "@/store/types/locations";

export default {
  data() {
    return {
      filter: "",
      sortOrder: 1,
      sortOrderOptions: [ 
        { id: 1, text: "Asc"},
        { id: 2, text: "Desc"},
      ],
      showLoader: true,
      perPage: 20,   
      currentPage: 1,
      perPageOptions: [
        { id: 10, text: "10 " + this.$t("menu.per_page") },
        { id: 20, text: "20 " + this.$t("menu.per_page") },
        { id: 50, text: "50 " + this.$t("menu.per_page") },
      ],
    };
  },

  computed: {
    ...mapGetters(["getUserKeys", "getLocationCompanyId"]),
    keysList() {
      return this.getUserKeys
        .filter((item) =>
          item.Key.Name.toLowerCase().includes(this.filter.toLowerCase())
        )
        .sort((i1, i2) => {
          if (this.sortOrder == 1) {
            return i1.Key.Name.toUpperCase() > i2.Key.Name.toUpperCase()
              ? 1
              : -1;
          } else {
            return i1.Key.Name.toUpperCase() < i2.Key.Name.toUpperCase()
              ? 1
              : -1;
          }
        });
    },
    paginatedList(){
      return this.keysList.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    }
  },
  created() {
    this.$store.dispatch(USER_GET_KEYS).then(() => (this.showLoader = false));
    this.$store.dispatch(LOCATIONS_GET_ALL_LOCATIONS);
  },
  methods: {},
  components: {
    KeyListItem,
    EmptyList,
    "zs-list-transition": ListTransition,
    Select2,
  },
};
</script>
<style>
.select2-container--default .select2-results > .select2-results__options {
  overflow: hidden;
}
</style>